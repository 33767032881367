import {
  computed, onBeforeMount, ref, watch,
} from '@vue/composition-api'
import { useClipboard } from '@vueuse/core'

import store from '@/store'
import { convertISODateTime } from '@/@core/utils/filter'

import storeModule from '@train/trainStoreModule'

import useToast from '@useToast'

export default function useTrainTicketListHandle() {
  if (!store.hasModule('app-train')) {
    store.registerModule('app-train', storeModule) // NOTE: register store khi import file js này, đến hiện tại thì vẫn oke
  }

  const stateCategory = computed(() => store.state['app-train'].category)
  const getListTrainStation = computed(() => store.getters['app-train/getListTrainStation']) // (array) list trạm dừng
  const stateSetting = computed(() => store.state['app-train'].settings)

  onBeforeMount(() => {
    if (!stateCategory.value || !getListTrainStation.value) {
      store.dispatch('app-train/handleGetCategory')
    }
    if (!stateSetting.value) {
      store.dispatch('app-train/handleGetSetting')
    }
  })
  const { toastError } = useToast()

  const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])

  const loading = ref(false)

  const refTrainTicketListTable = ref(null)

  const sizePerPage = ref(40)
  const totalTickets = ref(0)
  const currentPage = ref(1)

  const dataMeta = computed(() => {
    const localItemsCount = refTrainTicketListTable.value
      ? refTrainTicketListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTickets.value,
    }
  })
  const tableColumns = [
    { label: 'no', key: 'no' },
    { label: 'bookingCode', key: 'bookingCode' },
    { label: 'ticketId', key: 'ticketId' },
    { label: 'customerName', key: 'customerName' },
    { label: 'bookingInfos', key: 'bookingInfos' },
    { label: 'totalPrice', key: 'totalPrice' },
    { label: 'totalPriceTicket', key: 'totalPriceTicket' },
    { label: 'createdAt', key: 'createdAt', sortable: true },
    { label: 'createdBy', key: 'createdBy' },
    { label: 'status', key: 'status' },
    { label: 'actions', key: 'actions' },
  ]

  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  // Filter
  const startDateFilter = ref(null)
  const endDateFilter = ref(null)
  const startTripDateFilter = ref(null)
  const endTripDateFilter = ref(null)
  const createdByFilter = ref(null)
  const agencyCodeFilter = ref(null)
  const searchTextFilter = ref(null)
  const bookingCodeFilter = ref(null)
  const statusFilter = ref(null)

  // Empty filter
  const isEmptyFilter = computed(
    () => !!(
      startDateFilter.value === null
        && endDateFilter.value === null
        && startTripDateFilter.value === null
        && endTripDateFilter.value === null
        && createdByFilter.value === null
        && agencyCodeFilter.value === null
        && searchTextFilter.value === null
        && bookingCodeFilter.value === null
        && statusFilter.value === null
    ),
  )

  const refetchData = () => {
    if (refTrainTicketListTable.value) {
      refTrainTicketListTable.value.refresh()
    }
  }

  const clearFilter = () => {
    startDateFilter.value = null
    endDateFilter.value = null
    startTripDateFilter.value = null
    endTripDateFilter.value = null
    createdByFilter.value = null
    agencyCodeFilter.value = null
    searchTextFilter.value = null
    bookingCodeFilter.value = null
    statusFilter.value = null
    refetchData()
  }

  watch(
    [currentPage, sizePerPage, sortBy, isSortDirDesc, searchTextFilter, bookingCodeFilter],
    () => {
      refetchData()
    },
  )

  function fetchBookingList(ctx, callback) {
    loading.value = true
    const payload = {
      size: sizePerPage.value,
      page: currentPage.value,
      searchText: searchTextFilter.value ?? undefined,
      bookingCode: bookingCodeFilter.value ?? undefined,
      createdById: createdByFilter.value?.id ?? undefined,
      agencyCode: agencyCodeFilter.value?.agencyCode ?? undefined,
      startDate: startDateFilter.value ? `${startDateFilter.value}` : undefined,
      endDate: endDateFilter.value ? `${endDateFilter.value}` : undefined,
      startTripDate: startTripDateFilter.value ? `${startTripDateFilter.value}` : undefined,
      endTripDate: endTripDateFilter.value ? `${endTripDateFilter.value}` : undefined,
      status: statusFilter.value ?? undefined,
      sortBy: sortBy.value ? sortBy.value : 'createdAt',
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
    }
    store.dispatch('app-train/fetchBookingList', payload)
      .then(res => {
        totalTickets.value = res.total
        callback(res.items)
      })
      .catch(error => {
        console.error({ error })
        toastError({
          title: 'messagesList.error',
          content: 'Tải danh sách booking thất bại',
        })
      })
      .finally(() => {
        loading.value = false
      })
  }

  const disabledCopy = ref(false)

  const { copy } = useClipboard()
  function copyText(text) {
    disabledCopy.value = true
    setTimeout(() => {
      disabledCopy.value = false
    }, 1000)

    copy(text)
      .then(() => {
        this.$root.toastSuccess('reservation.sms.copySuccess')
      })
      .catch(() => {
        this.$root.toastError('reservation.sms.copyFail')
      })
  }

  function featureDevelopment() {
    toastError({ title: 'featureInDevelopment' })
  }

  function resolveTime(date, time) {
    const result = convertISODateTime(date)
    // return `${result.hourMin} ${result.dayAndMonth}`
    return `${time} ${result.dayAndMonth}`
  }

  return {
    fetchBookingList,
    refTrainTicketListTable,
    tableColumns,
    sortBy,
    isSortDirDesc,
    sizePerPage,
    totalTickets,
    currentPage,
    isRoleF1,
    disabledCopy,
    copyText,
    featureDevelopment,
    resolveTime,
    dataMeta,
    startDateFilter,
    endDateFilter,
    startTripDateFilter,
    endTripDateFilter,
    createdByFilter,
    agencyCodeFilter,
    searchTextFilter,
    bookingCodeFilter,
    statusFilter,
    isEmptyFilter,
    clearFilter,
    refetchData,
  }
}
