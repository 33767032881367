<template>
  <IAmOverlay :loading="loading">
    <b-card
      id="traing-ticket-list"
      no-body
      class="mb-1 box-shadow-0 border p-1"
    >
      <TrainTicketListFilter
        :search-text-filter.sync="searchTextFilter"
        :booking-code-filter.sync="bookingCodeFilter"
        :created-by-filter.sync="createdByFilter"
        :agency-code-filter.sync="agencyCodeFilter"
        :start-date-filter.sync="startDateFilter"
        :end-date-filter.sync="endDateFilter"
        :start-trip-date-filter.sync="startTripDateFilter"
        :end-trip-date-filter.sync="endTripDateFilter"
        :statusFilter.sync="statusFilter"
        :status-options="statusTrainOptions"
        :empty-filter="isEmptyFilter"
        @fetch-data="refetchData"
        @reset="clearFilter"
      />

      <b-table
        ref="refTrainTicketListTable"
        :style="maxHeightStyle"
        :sticky-header="true"
        :items="fetchBookingList"
        :striped="true"
        class="position-relative p-0 rounded"
        :fields="tableColumns"
        :sort-desc.sync="isSortDirDesc"
        :sort-by.sync="sortBy"
        :hover="true"
        :borderless="true"
        :busy="loading"
        responsive
        no-border-collapse
        show-empty
        :empty-text="$t('noMatchingResult')"
        small
      >
        <template #table-busy>
          <div class="d-flex-center text-dark my-2 gap-2">
            <b-spinner class="align-middle" />
            <strong>{{ $t('loading') }}</strong>
          </div>
        </template>
        <!-- ANCHOR - Column Name -->
        <template
          v-for="column in tableColumns"
          #[`head(${column.key})`]="data"
        >
          <span
            :key="column.label"
            class="text-dark text-nowrap"
          >
            {{ $te(`train.list.columns.${data.label}`) ? $t(`train.list.columns.${data.label}`) : data.label }}
          </span>
        </template>

        <!-- ANCHOR - NO -->
        <template #cell(no)="data">
          <div class="text-nowrap">
            <span
              class="align-text-top text-capitalize font-weight-bold d-flex-center"
              style="color: #333333"
            >
              {{ ((currentPage - 1) * sizePerPage) + data.index + 1 }}
            </span>
          </div>
        </template>

        <!-- ANCHOR - bookingCode -->
        <template #cell(bookingCode)="data">
          <div class="d-flex-between">
            <!-- :disabled="!canAccess('booking.detailBooking')" -->
            <b-link
              :to="{ name: 'apps-train-ticketDetail', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap text-info pb-0 flex-1"
            >
              {{ data.item.bookingCode }}
            </b-link>
            <BButton
              v-b-tooltip.hover.window.top="$t('copy')"
              variant="flat-primary"
              class="p-50 rounded-circle ml-25"
              :disabled="disabledCopy"
              @click="copyText(data.item.bookingCode)"
            >
              <IAmIcon
                icon="copy"
                size="18"
                color="#2FA4E5"
              />
            </BButton>
          </div>
        </template>

        <!-- ANCHOR - ticketId -->
        <template #cell(ticketId)="{ item }">
          <div v-if="item.listPassenger && (item.bookingInfos?.length > item.listPassenger?.length+1)">
            <div
              v-for="(bookingInfo, index) in item.bookingInfos.slice(0, item.listPassenger.length)"
              :key="index"
            >
              <div class="d-flex-between gap-1 flex-nowrap">
                <div class="flex-1">
                  {{ bookingInfo.ticketId }}
                </div>
                <BButton
                  v-b-tooltip.hover.window.top="$t('copy')"
                  variant="flat-primary"
                  class="p-25 rounded-circle"
                  :disabled="disabledCopy"
                  @click="copyText(bookingInfo.ticketId)"
                >
                  <IAmIcon
                    icon="copy"
                    size="18"
                    color="#2FA4E5"
                  />
                </BButton>
              </div>
            </div>
            <small class="font-italic">
              (...{{ $t('train.list.and') }}
              <span class="fw-600">{{ item.bookingInfos?.length - item.bookingInfos.slice(0, item.listPassenger.length).length }}</span>
              {{ $t('train.list.otherTicket') }})
            </small>
          </div>
          <div v-else>
            <div
              v-for="(bookingInfo, index) in item.bookingInfos"
              :key="index"
            >
              <div class="d-flex-between gap-1 flex-nowrap">
                <div class="flex-1">
                  {{ bookingInfo.ticketId }}
                </div>
                <BButton
                  v-b-tooltip.hover.window.top="$t('copy')"
                  variant="flat-primary"
                  class="p-25 rounded-circle"
                  :disabled="disabledCopy"
                  @click="copyText(bookingInfo.ticketId)"
                >
                  <IAmIcon
                    icon="copy"
                    size="18"
                    color="#2FA4E5"
                  />
                </BButton>
              </div>
            </div>
          </div>
        </template>

        <template #cell(customerName)="data">
          <div
            v-for="(passengerName, passengerNameIndex) in (data.item?.listPassenger ?? [])"
            :key="passengerNameIndex"
          >
            <span style="line-height: 1.7">{{ passengerName?.fullName }}</span>
          </div>
        </template>

        <!-- ANCHOR - bookingInfos -->
        <template #cell(bookingInfos)="data">
          <div
            v-for="(trip, index) in resolveListTrip(data.item.listTrip)"
            :key="index"
            class="d-flex flex-nowrap gap-1 text-nowrap"
            style="line-height: 1.7"
          >
            {{ trip }}
          </div>
        </template>

        <!-- ANCHOR - totalPrice -->
        <template #cell(totalPrice)="data">
          <div>{{ formatCurrency(data.item.totalPricePaid) }}</div>
        </template>

        <!-- ANCHOR - totalPriceTicket -->
        <template #cell(totalPriceTicket)="data">
          <div>{{ formatCurrency(data.item.totalPriceTicket) }}</div>
        </template>

        <!-- ANCHOR - createdAt -->
        <template #cell(createdAt)="data">
          <div>{{ convertISODateTime(data.item.createdAt).time }}</div>
          <div>{{ convertISODateTime(data.item.createdAt).date }}</div>
        </template>

        <!-- ANCHOR - createdBy -->
        <template #cell(createdBy)="data">
          <div
            :id="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
            class="w-100 h-100 d-flex-center flex-column"
          >
            <!-- username -->
            <div class="text-info font-weight-bold text-body-3 text-nowrap">
              {{ data.item.createdBy.username.toUpperCase() }}
            </div>

            <!-- agency -->
            <div class="text-dark font-weight-bold text-body-3 text-nowrap">
              ({{
                isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000
                  ? data.item.createdBy.agency.parentAgency.agencyCode.toUpperCase()
                  : data.item.createdBy.agency.agencyCode.toUpperCase()
              }})
            </div>
            <b-tooltip
              :target="`booker-${data.item.createdBy.id}-${data.item.createdAt}`"
              triggers="hover"
              placement="top"
              boundary="viewport"
              variant="dark"
            >
              <BRow>
                <BCol
                  cols="4"
                  class="text-nowrap"
                >
                  Tên tài khoản:
                </BCol>
                <BCol cols="8">
                  <span class="font-weight-bolder">
                    {{ data.item.createdBy.lastName }}
                    {{ data.item.createdBy.firstName }}
                    ({{ data.item.createdBy.type }})
                  </span>
                </BCol>
                <BCol
                  cols="4"
                  class="text-nowrap"
                >
                  Mã tài khoản:
                </BCol>
                <BCol cols="8">
                  <span class="font-weight-bolder">{{ data.item.createdBy.employeeCode.toUpperCase() }}</span>
                </BCol>
                <BCol
                  cols="4"
                  class="text-nowrap"
                >
                  Tên đại lý:
                </BCol>
                <BCol cols="8">
                  <span class="font-weight-bolder">{{ data.item.createdBy.agency.agencyName }}</span>
                </BCol>
                <BCol
                  cols="4"
                  class="text-nowrap"
                >
                  Mã đại lý:
                </BCol>
                <BCol cols="8">
                  <span class="font-weight-bolder">{{ data.item.createdBy.agency.agencyCode.toUpperCase() }}</span>
                </BCol>
                <BCol
                  v-if="isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000"
                  cols="4"
                  class="text-nowrap"
                >
                  Tên ĐL chủ quản:
                </BCol>
                <BCol
                  v-if="isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000"
                  cols="8"
                >
                  <span class="font-weight-bolder">{{ data.item.createdBy.agency.parentAgency.agencyName }}</span>
                </BCol>
                <BCol
                  v-if="isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000"
                  cols="4"
                  class="text-nowrap"
                >
                  Mã ĐL chủ quản:
                </BCol>
                <BCol
                  v-if="isRoleF1 && data.item.createdBy.agency.parentAgency && data.item.createdBy.agency.parentAgency.id !== 1000000"
                  cols="8"
                >
                  <span class="font-weight-bolder">{{ data.item.createdBy.agency.parentAgency.agencyCode.toUpperCase() }}</span>
                </BCol>
              </BRow>
            </b-tooltip>
          </div>
        </template>

        <!-- ANCHOR - status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveTrainTicketStatusVariant(data.item.status)}`"
            :class="`badge-glow round text-uppercase`"
          >
            {{ $t(`train.status.${data.item?.status}`) }}
          </b-badge>
        </template>

        <!-- ANCHOR - actions -->
        <template #cell(actions)="data">
          <div class="d-flex align-items-center gap-2">
            <b-button
              v-b-tooltip.hover.v-info.window
              variant="flat-info"
              :title="isMobileView ? '' : $t('train.btn.viewDetail')"
              class="btn-icon p-50"
              :to="{ name: 'apps-train-ticketDetail', params: { id: data.item.id } }"
            >
              <IAmIcon
                icon="eye"
                size="20"
              />
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-info.window
              variant="light"
              :title="isMobileView ? '' : $t('reservation.exportTicket')"
              class="btn-icon p-50"
              @click="showPopupTicketFace(data.item.id)"
            >
              <img src="@icons/plane-ticket-blue.svg">
            </b-button>

            <!-- <b-button
              v-b-tooltip.hover.v-primary.window
              variant="flat-info"
              :title="isMobileView ? '' : $t('')"
              class="btn-icon p-50"
              @click="featureDevelopment"
            >
              <IAmIcon
                icon="fileArrowDown"
                size="20"
              />
            </b-button>

            <b-button
              v-b-tooltip.hover.v-primary.window
              variant="flat-info"
              :title="isMobileView ? '' : $t('')"
              class="btn-icon p-50"
              @click="featureDevelopment"
            >
              <IAmIcon
                icon="undo"
                size="20"
              />
            </b-button> -->
          </div>
        </template>
      </b-table>

      <div
        ref="refPagination"
        class="px-2 py-1"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex-center justify-content-md-start"
          >
            <span class="text-muted">
              {{ $t('paginationText.showing') }}
              <b-dropdown
                v-model="sizePerPage"
                variant="warning"
                :text="`${sizePerPage}`"
                class="per-page-selector d-inline-block p-0 mx-25"
              >
                <b-dropdown-item
                  v-for="item in sizePerPageLgOptions"
                  :key="item"
                  @click="sizePerPage = item"
                >
                  {{ item }}
                </b-dropdown-item>
              </b-dropdown>
              {{ $t('tickets') }}
              <div>{{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }} {{ dataMeta.of }} {{ $t('tickets') }}</div>
            </span>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex-center justify-content-md-end mt-1 mt-md-0 pr-lg-3"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTickets"
              :per-page="sizePerPage"
              first-number
              last-number
              class="pagination-warning mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </IAmOverlay>
</template>
<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol, BDropdown, BDropdownItem, BLink, BPagination, BRow, BSpinner, BTable, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import uniq from 'lodash/uniq'

import IAmOverlay from '@/components/IAmOverlay.vue'
import { convertISODateTime, formatCurrency } from '@/@core/utils/filter'
import {
  sizePerPageLgOptions,
} from '@/constants/selectOptions'
import { statusTrainOptions } from '@/constants/train'

import useTrainTicketListHandle from './useTrainTicketListHandle'
import TrainTicketListFilter from './TrainTicketListFilter.vue'
import { resolveTrainTicketStatusVariant } from '../useTrainHandle'

export default {
  components: {
    TrainTicketListFilter,
    BTable,
    BTooltip,
    BRow,
    BCol,
    IAmOverlay,
    BSpinner,
    BLink,
    BButton,
    BBadge,
    BCard,
    BDropdownItem,
    BPagination,
    BDropdown,
  },
  directives: { 'b-tooltip': VBTooltip },

  setup() {
    const {
      loading,
      fetchBookingList,
      refTrainTicketListTable,
      tableColumns,
      isSortDirDesc,
      sortBy,
      sizePerPage,
      totalTickets,
      currentPage,
      isRoleF1,
      copyText,
      disabledCopy,
      featureDevelopment,
      resolveTime,
      dataMeta,
      startDateFilter,
      endDateFilter,
      startTripDateFilter,
      endTripDateFilter,
      createdByFilter,
      agencyCodeFilter,
      searchTextFilter,
      bookingCodeFilter,
      statusFilter,
      isEmptyFilter,
      clearFilter,
      refetchData,
    } = useTrainTicketListHandle()

    const refPagination = ref()
    const maxHeightStyle = computed(() => {
      const offsetTop = refTrainTicketListTable.value?.$el?.offsetTop || 0
      const paginationHeight = refPagination.value?.offsetHeight || 0
      const rs = (offsetTop + paginationHeight) > 0 ? { maxHeight: `calc(90vh - ${offsetTop + paginationHeight + 20}px)` } : { maxHeight: '70vh' }
      return rs
    })

    function resolveListTrip(listTrip) {
      if (!listTrip.length) return []
      return uniq(listTrip
        .map(trip => (trip?.tripInfos || [])
          .map(tripInfo => `
            ${tripInfo.departure}-${tripInfo.arrival} ${resolveTime(tripInfo.departureTime, tripInfo.departureHour)}-${resolveTime(tripInfo.arrivalTime, tripInfo.arrivalHour)} ${tripInfo.trainCode}
          `))
        .flat())
    }

    async function showPopupTicketFace(id) {
      const path = `bookings/${id}/ticket`
      const route = this.$router.resolve({ path })
      window.open(route.href)
    }

    return {
      fetchBookingList,
      refTrainTicketListTable,
      refPagination,
      tableColumns,
      isSortDirDesc,
      sortBy,
      sizePerPage,
      totalTickets,
      currentPage,
      isRoleF1,
      maxHeightStyle,
      loading,
      copyText,
      disabledCopy,
      formatCurrency,
      convertISODateTime,
      featureDevelopment,
      resolveTime,
      resolveTrainTicketStatusVariant,
      sizePerPageLgOptions,
      dataMeta,
      startDateFilter,
      endDateFilter,
      startTripDateFilter,
      endTripDateFilter,
      createdByFilter,
      agencyCodeFilter,
      searchTextFilter,
      bookingCodeFilter,
      statusFilter,
      isEmptyFilter,
      clearFilter,
      statusTrainOptions,
      refetchData,
      resolveListTrip,

      showPopupTicketFace,
    }
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector ::v-deep {
  width: fit-content !important;
  button {
    padding: 4px 16px;
    margin-bottom: 4px;
  }
}
</style>
